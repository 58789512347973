<script>
import CurrencyInput from "@/components/CurrencyInput";

export default {
  components: {CurrencyInput},
  props: ["purchase_order","cash_accounts","vatValue"],
  data() {
    return {
      tomorrow:null,
      due_date:null,
      isOnCredit: true,
      paymentTypes: [
        { id: 1, name: "مديونية", name_en: "Pay On Credit" },
        { id: 2, name: "دفع جزئي", name_en: "Partial Payment" },
        { id: 3, name: "دفع كامل", name_en: "Full Payment" },
      ],
      is_du_date: false,
      selectedPaymentType:null,
      addPayAccounts: [{}],
      matchedAccounts:[]
    };
  },
  watch: {

  },
  methods: {
    completeOrder() {
      console.log("this.addPayAccounts",this.addPayAccounts)
      this.http
          .post("purchase-orders/complete-order", {
            due_date:this.due_date,
            id:this.purchase_order.id,
            accounts:this.addPayAccounts
          })
          .then((resp) => {
            if (resp.status) {
              console.log(resp);
              this.$emit("onComplete")
              setTimeout(() => {
                document.querySelector(".btn-close-complete")?.click();
                 this.popup.alert({
        title: "popups.done",
            msg: "popups.success",
      });
              }, 200);
            }
          });
    },
    // keep this
    startPaymentProcess() {
      if (this.selectedPaymentType == 1) {
        this.isOnCredit = true;
        this.addPayAccounts = [{}];
      } else {
        this.isOnCredit = false;
      }
      if (this.selectedPaymentType > 2) {
        this.is_du_date = true;
      } else {
        this.is_du_date = false;
      }
    },
    accountSearch(query){
      this.matchedAccounts = this.cash_accounts.filter(account=>
          (
              account.name.toLowerCase().includes(query.toLowerCase()) ||
              account.name_en.toLowerCase().includes(query.toLowerCase())
          ) &&
          !this.addPayAccounts.some(payAccount=>payAccount?.cash_account?.cash_account_id==account.cash_account_id)

      )
      console.log("this.matchedAccounts",this.matchedAccounts)
    },
    validateCashAccount(payAccount){
      if(parseFloat(payAccount.cash_account.balance)==0){
        payAccount.cash_account = ""
      }
    },
    showLabel(account){
      console.log("ACCOUNT",account)
      return (this.$i18n.locale == "ar"
          ? account?.name
          : account?.name_en) + " - " +
              this.processCurrency(account,account.balance)
    }
  },
  computed:{
    validateFullPayment(){
      return this.selectedPaymentType<3 || this.totalAddPayAccounts >= parseFloat(this.purchase_order?.untaxedTotal) + parseFloat(this.purchase_order?.taxTotal)
    },
    totalAddPayAccounts(){
      return this.addPayAccounts.filter(account=>account.value)
          .reduce((sum,account)=>{
            console.log("this.purchase_order?.currency?.id",this.purchase_order?.currency?.id,"sum",sum,"account",account)
            if(this.purchase_order?.currency?.id != account.cash_account.currency_id && this.purchase_order?.currency?.is_functional){
              sum += parseFloat(account.value) * parseFloat(account.cash_account.current_rate)
            }
            else if(this.purchase_order?.currency?.id !=account.cash_account.currency_id && account.cash_account.is_functional){
              sum += parseFloat(account.value) / parseFloat(this.purchase_order?.currency?.current_rate)
            }
            else if(this.purchase_order?.currency?.id != account.cash_account.currency_id){
              sum += parseFloat(account.value) * parseFloat(  this.purchase_order?.currency?.current_rate) / parseFloat(account.cash_account.current_rate)
            }
            else{
              sum += parseFloat(account.value)
            }
            return sum
          },0)
    }
  },
  mounted() {
    this.selectedPaymentType = this.paymentTypes[0]?.id;
    this.tomorrow = new Date()
    this.tomorrow.setDate((new Date()).getDate() + 1)
    console.log("this.tomorrow",this.tomorrow)
    this.tomorrow = this.tomorrow.toISOString().split('T')[0]

    console.log("cash_accounts",this.cash_accounts);
  },
};
</script>

<template>
  <!--Start Add Modal-->
  <div class="modal fade" id="completeModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
       :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <form @submit.prevent="completeOrder()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.complete_purchase") }}
            </h5>
            <button type="button" class="btn-close-complete btn-close" data-bs-dismiss="modal" aria-label="Close"
                    style="margin: 0"></button>
          </div>

          <div class="modal-body">
            <label for="">{{ $t("purchases.supplier") }} </label>
            <div class="purchase-container">
              <div class="d-flex justify-content-between">
                <h4>{{ purchase_order?.supplier?.name }}</h4>
                <h4>
                  <span> {{ purchase_order?.supplier?.evaluation }} </span><i class="bx bx-award text-success"></i>
                </h4>
              </div>
              <i class="bx bx-phone text-dark"></i><span> {{ purchase_order?.supplier?.tel }}</span>
            </div>
            <hr />
            <div class="row mt-2">
              <div class="row d-flex align-items-center">
                <div class="col">
                  <h6 for="asset_id">{{ $t("purchases.items") }}</h6>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-striped table-centered table-nowrap align-middle">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t("purchases.name") }}</th>
                    <th>{{ $t("purchases.unit_price") }}</th>
                    <th>{{ $t("purchases.qty") }}</th>
                    <th>{{ $t("purchases.vat") }}</th>
                    <th>{{ $t("purchases.sub_total") }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(
                          item, index
                        ) in purchase_order?.items" :key="item">
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{ $i18n.locale == 'ar' ? item?.product?.name : item?.product?.name_en }}
                    </td>
                    <td>
                      {{ parseFloat(item.unit_price)?.toLocaleString() }}
                    </td>
                    <td>{{ parseInt(item.qty)?.toLocaleString() }}</td>
                    <td>{{ item.vat ? vatValue*100+"%" : "-" }}</td>
                    <td>
                      {{
                        (item.vat
                                ? parseInt(item.qty) *
                                parseFloat(item.unit_price) +
                                parseInt(item.qty) *
                                parseFloat(item.unit_price) *
                                parseFloat(vatValue)
                                : parseInt(item.qty) * parseFloat(item.unit_price)
                        ).toLocaleString()
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <th>{{ $t("purchases.total") }}</th>
                    <th class="total-style">
                      {{
                        processCurrency(purchase_order?.currency,(parseFloat(purchase_order?.untaxedTotal) + parseFloat(purchase_order?.taxTotal)),true)
                      }}
                    </th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <div class="row mt-2">
              <div class="col">
                <label for="asset_id">{{
                    $t("purchases.payment_type")
                  }}</label>
                <div class="purchase-container">
                  <select class="form-select mb-2" v-model="selectedPaymentType" @change="startPaymentProcess()"
                          required id="">
                    <option v-for="paymentType in paymentTypes" :key="paymentType" :value="paymentType.id">
                      {{
                        $i18n.locale == "ar"
                            ? paymentType.name
                            : paymentType.name_en
                      }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col">
                <label class="form-label">{{
                    $t("purchases.pay_date")
                  }}</label>
                <div class="purchase-container">
                  <input v-model="due_date" :min="tomorrow" type="date" :disabled="is_du_date" id="" class="form-control" />
                </div>
              </div>
            </div>

            <div class="">
              <div class="mt-3" v-if="!isOnCredit">
                <hr />
                <div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <label for="asset_id">{{
                          $t("purchases.chose_acccount")
                        }}</label>
                    </div>
                    <button type="button" @click="addPayAccounts.push({})" class="btn">
                      <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
                    </button>
                  </div>
                  <div v-for="(payAccount, aIndex) in addPayAccounts" :key="payAccount">
                    <div class="purchase-container d-flex">
<!--                          @item-select="selectSupplier"-->
                      <div class="row w-100">
                        <div class="col-12 col-md-5 mb-2 mb-md-1">
                          <AutoComplete
                              v-model="payAccount.cash_account"
                              :suggestions="matchedAccounts"

                              @item-select="validateCashAccount(payAccount)"
                              @complete="accountSearch($event.query)"
                              :complete-on-focus="true"
                              :optionLabel="showLabel"
                              style="color: #333 !important;width:100%" />
                        </div>
                        <div class="col-12 col-md-5 mb-2 mb-md-1">
                          <currency-input :disabled="!payAccount.cash_account" v-model="payAccount.value" :max="payAccount?.cash_account?.balance"
                                          class="form-control mb-2 mx-1" :placeholder="$t('purchases.amount')" ></currency-input>
                        </div>
                        <div class="col-12 col-md-2 mb-2 mb-md-1">
                          <button type="button" role="button" @click="addPayAccounts.splice(aIndex, 1)"
                                  :disabled="addPayAccounts.length == 1" class="btn btn-danger">
                            <i class="bx bx-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6>{{$t("purchases.total")}}: {{processCurrency(purchase_order.currency,totalAddPayAccounts,true)}}</h6>
                  <h6 v-if="selectedPaymentType==3">{{$t("purchases.remaining")}}: {{processCurrency(purchase_order.currency,(parseFloat(purchase_order?.untaxedTotal) + parseFloat(purchase_order?.taxTotal) - totalAddPayAccounts),true)}}</h6>
                </div>

              </div>
              <hr>
              <div v-if="purchase_order.prepayment">
                {{$t("purchases.advance_to_supplier")}}: {{processCurrency(purchase_order.currency,purchase_order.prepayment,true)}}
              </div>
              <div v-if="purchase_order.previous_payable">
                {{$t("purchases.payable_to_supplier")}}: {{processCurrency(purchase_order.currency,purchase_order.previous_payable,true)}}
              </div>
            </div>
          </div>

          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">


            <button :disabled="!validateFullPayment" type="submit" class="btn btn-primary">
              {{ $t("popups.complete_purchase") }}
            </button>

            <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Modal-->
</template>

<style scoped>
.purchase-container {
  background: #fafafa;
  margin: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
}
</style>
