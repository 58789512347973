<script>
import Layout from "../../../layouts/main";

import PageHeader from "@/components/page-header";
import PurchaseOrderDetails from "./PurchaseOrderDetails.vue";
import GoodInTransit from "./GoodInTransit";
import DebitNote from "@/router/views/Purchases/PurchasesPage/DebitNote";
import {Modal} from "bootstrap";
import CompleteOrder from "./CompleteOrder.vue";
import CurrencyInput from "@/components/CurrencyInput";

export default {
  page: {
    meta: [
      {
        name: "description"
      },
    ],
  },
  components: {
    CurrencyInput,
    Layout,
    PageHeader,
    PurchaseOrderDetails,
    CompleteOrder,
    GoodInTransit,
    DebitNote
  },
  data() {
    return {
      purchase_order_modal:null,
      purchase_details_modal:null,
      debit_note_modal:null,
      purchase_complete_modal:null,
      good_in_transit_modal:null,
      currenciesArray: [],
      purchases: [],
      inventories: [],
      purchase_order: {
        items:[{}]
      },
      matchedProducts: [],
      edit_status: {
        status: false,
      },
      order_details: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      agents: [],
      suppliers: [],
      searchModel:"",
      selectedSupplier: null,
      cash_accounts: [],
      currencies: [],
      currency: {},
      vatValue: 0,

      // complete order part
      selectedOrder: {},
      landedCosts: [],
      landedCostTypes: [],


    };
  },
  computed: {
    untaxedTotal(){
      return this.purchase_order.items.reduce((sum,item)=>{
        // return sum + (item.discount? (item.number? parseFloat(item.qty ? item.qty - item.number : 0) : parseFloat(item.qty ? item.qty : 0)) : parseFloat(item.qty ? item.qty : 0)) *
        return sum + parseFloat(item.total_price ? item.total_price : 0)
      },0)
    },
    taxTotal(){
      return this.purchase_order.items.reduce((sum,item)=>{
        return sum + parseFloat(item.total_price ? item.total_price : 0) *
            (item?.vat ? (parseFloat(this.vatValue)) : 0)
      },0)
    },
    enableCreatePurchaseButton(){
      return this.purchase_order.items.every(item=>item.qty) && this.purchase_order.inventory_id
    }
  },
  methods: {
    search(page) {
      this.http
          .post("purchase-orders/search", {
            search: this.searchModel,
            limit: this.limit,
            page
          })
          .then((res) => {
            this.page = page
            this.tot_pages = Math.ceil(res.tot / this.limit);
            this.purchases = res.data;
          });
    },
    async showDetailsModal(id){
      this.purchase_order = await this.getSinglePurchaseOrder(id,false)
      this.purchase_order.untaxedTotal = this.untaxedTotal
      this.purchase_order.taxTotal = this.taxTotal
      this.purchase_details_modal.show()
    },
    async preCompleteOrder(id){
      this.getPurchaseConfigurations()
      this.purchase_order = await this.getSinglePurchaseOrder(id,true)
      this.purchase_order.untaxedTotal = this.untaxedTotal
      this.purchase_order.taxTotal = this.taxTotal
      this.purchase_complete_modal.show()
    },
    async preDebitNote(id){
      this.getPurchaseConfigurations()
      this.purchase_order = await this.getSinglePurchaseOrder(id,true)
      await this.getLandedCosts()
      this.purchase_order.untaxedTotal = this.untaxedTotal
      this.purchase_order.taxTotal = this.taxTotal
      this.debit_note_modal.show()
    },
    async createPurchaseOrder() {
      this.purchase_order.items.forEach((item) => {
        item.unit_price = (parseFloat(item.total_price)/parseFloat(item.qty))
      })
      console.log("Purchase Order",this.purchase_order)
      let answer = await this.popup.confirm({
        title:"popups.confirm",
        msg:"popups.confirm"
      })
      if(answer)
        this.http.post("purchase-orders", {
          agent_id: this.purchase_order.supplier?.id,
          inventory_id: this.purchase_order.inventory_id,
          purchase_item_type_id: this.purchase_order.purchase_item_type.id,
          driver: this.purchase_order.driver,
          plate_num: this.purchase_order.plate_num,
          comment: this.purchase_order.comment,
          currency_id: this.purchase_order?.currency?.id,
          items: this.purchase_order.items,
      }).then((resp) => {
        if (resp) {
          this.supplier = {};
          this.purchase_order = {
            items:[{}]
          }
          this.purchase_order_modal.hide()
          this.get(this.page);
        }
      });
    },
    async editPurchaseOrder() {
      this.purchase_order.items.forEach((item) => {
        item.unit_price = (parseFloat(item.total_price)/parseFloat(item.qty))
      })
      console.log("Purchase Order",this.purchase_order)
      let answer = await this.popup.confirm({
        title:"popups.confirm",
        msg:"popups.confirm"
      })
      if(answer)
        this.http.post("purchase-orders/edit", {
          id: this.purchase_order?.id,
          agent_id: this.purchase_order.supplier?.id,
          inventory_id: this.purchase_order.inventory_id,
          purchase_item_type_id: this.purchase_order.purchase_item_type.id,
          driver: this.purchase_order.driver,
          plate_num: this.purchase_order.plate_num,
          comment: this.purchase_order.comment,
          currency_id: this.purchase_order?.currency?.id,
          items: this.purchase_order.items,
      }).then((resp) => {
        if (resp) {
          this.supplier = {};
          this.purchase_order = {
            items:[{}]
          }
          this.purchase_order_modal.hide()
          this.get(this.page);
        }
      });
    },
    getLandedCosts() {
      return new Promise(resolve => {
        this.http
            .post("purchase-orders/landed-costs/pagination", {
              id: this.purchase_order.id,
            })
            .then((res) => {
              if (res.status) {
                res.data.sort((a,b)=>b.id - a.id)
                this.landedCosts = res.data;
              }
              resolve()
            });
      })

    },
    get(page) {
      console.log(page);
      console.log({
        selected_company_id: this.$store?.state?.authfack?.selected_company_id,
      });
      this.http
        .post("purchase-orders/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.purchases = res.data;
          console.log({ purchases: this.purchases });

          this.purchases.forEach((purchase) => {
            if (purchase?.du_time) {
              purchase.due_date = purchase?.due_date.split("T")[0];
            }
          });
        });
    },
    async preGoodInTransit(id){
      this.purchase_order = await this.getSinglePurchaseOrder(id,false)
      await this.getLandedCosts()
      this.purchase_order.untaxedTotal = this.untaxedTotal
      this.purchase_order.taxTotal = this.taxTotal
      this.good_in_transit_modal.show()
    },
    async preEditOrder(id){
      this.purchase_order = await this.getSinglePurchaseOrder(id,false)
      this.currencyChanged()
      this.purchase_order_modal.show()
    },
    async preAddOrder(){
      this.purchase_order =  {
        items:[{}]
      }
      this.purchase_order_modal.show()
    },
    getSinglePurchaseOrder(id,forCompleteOrder){
      return new Promise(resolve => {
        this.http.post("purchases/single-order",{
          id,
          forCompleteOrder
        })
            .then(resp=>{
              resp.purchase_order.items.forEach(item=>item.vat = item.vat==null? false : true)
              resp.purchase_order.items.forEach(item=>item.discount = item.discount? true : false)
              resp.purchase_order.items.forEach(item=>item.total_price = (item.unit_price * item.qty))

              resolve(resp.purchase_order)

            })
      })
    },
    deleteOrder(id) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("purchase-orders", id).then(() => {

            this.get(this.page);
          });
        }
      });
    },
    selectSupplier() {
      this.http
        .post("purchases-agents/select-supplier", {
          id: this.purchase_order.supplier.id,
        })
        .then((res) => {
          if (res.status) {
            this.purchase_order.currenciesArray = res.currencies;
            this.purchase_order.purchase_agent_item_types = res.supplier.purchase_agent_item_types;
            // if the functional currency is the supplier currency make it selected
            if(this.purchase_order?.currenciesArray?.length == 1)
              this.purchase_order.currency = this.purchase_order.currenciesArray[0]
          }
        });
    },
    searchProduct(search) {
      this.http
          .post("products/search-in-supplier", {
            search,
            purchase_agent_id:this.purchase_order.supplier.id,
            existingProducts:[],//this.purchase_order.items.filter(i=>typeof(i.product)=='object').map(i=>i.product.id)
          })
          .then((res) => {
            if (res.status) {
              this.matchedProducts = res.products
            }
          });
    },
    supplierSearch(search) {
      this.http
        .post("purchases-agents/search", {
          search,
          type:this.purchase_order.type
        })
        .then((res) => {
          if (res.status) {
            this.suppliers = res.data
          }
        });
    },
    showSupplierLabel(one) {
      return one.code + '-' + one.name;
    },
    showProductsLabel(one) {
      return this.$i18n.locale == 'ar'? one.name : one.name_en;
    },
    getPurchaseConfigurations() {
      this.http
        .get("purchases/configurations")
        .then((res) => {
          this.vatValue = parseFloat(res.vat_value.value);
          this.cash_accounts = res.cash_accounts;
          this.inventories = res.inventories;
          this.landedCostTypes = res.landed_cost_types;
        });
    },
    currencyChanged(){
      this.purchase_order.items
          .filter(item=>item.product)
          // .forEach(item=>this.selectProduct(item))
    },
    setGrossWeight(){
      this.purchase_order.items.forEach(item=>  {
        item.qty = parseFloat(item.weight)
    })
    },
    // selectProduct(item){
    //   if(this.purchase_order.currency.id != item.product.purchase_currency.id && this.purchase_order.currency.is_functional){
    //     item.total_price = parseFloat(item.product.purchase_price * item.product.purchase_currency.current_rate).toFixed(2)
    //   }
    //   else if(this.purchase_order.currency.id != item.product.purchase_currency.id && item.product.purchase_currency.is_functional){
    //     item.total_price = parseFloat(item.product.purchase_price / this.purchase_order.currency.current_rate).toFixed(2)
    //   }
    //   else if(this.purchase_order.currency.id != item.product.purchase_currency.id){
    //     item.total_price = parseFloat(item.product.purchase_price * item.product.purchase_currency.current_rate / this.purchase_order.currency.current_rate).toFixed(2)
    //   }
    //   else{
    //     item.total_price = parseFloat(item.product.purchase_price).toFixed(2)
    //   }

    // }
  },
  mounted() {
    this.purchase_order_modal = new Modal("#addModal")
    this.purchase_details_modal = new Modal("#details")
    this.purchase_complete_modal = new Modal("#completeModal")
    this.good_in_transit_modal = new Modal("#good-in-transit")
    this.debit_note_modal = new Modal("#debitNoteModal")

    this.get(1);
    this.getPurchaseConfigurations();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :title="$t('menu.menuitems.purchases.text')" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="search-box chat-search-box w-50">
                  <div class="position-relative">
                    <input @input="search(1)" type="search" class="form-control fa-lg text-light"
                           :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" :placeholder="$t('popups.search')" v-model="searchModel"
                           style="background-color: #2a3042 !important" />
                    <i class="bx bx-search-alt search-icon text-light"></i>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <button style="float: inline-end !important; "
                        @click="preAddOrder()"
                        class="btn btn-light float-end mb-4">
                  <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
                </button>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table table-centered table-striped table-nowrap align-middle">
                <thead>
                <tr class="text-light" style="background-color: #2a3042 !important">
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("purchases.agent") }}</th>
                  <th scope="col">{{ $t("purchases.currency") }}</th>
                  <th scope="col">{{ $t("purchases.total") }}</th>
                  <th scope="col">{{ $t("purchases.paid") }}</th>
                  <th scope="col">{{ $t("purchases.receive") }}</th>
                  <th scope="col">{{ $t("purchases.created") }}</th>
                  <th scope="col">{{ $t("purchases.operations") }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="app in purchases" :key="app" class="">
                  <td># {{ app?.id }}</td>
                  <td>
                    {{ app?.purchase_agent?.name ? app?.purchase_agent?.name : "-" }}
                  </td>
                  <td>
                    {{
                      $i18n.locale == "ar"
                          ? app?.currency?.name
                          : app?.currency?.name_en
                    }}
                  </td>
                  <td>
                    {{app?.currency?.is_functional ? processCurrency(app?.currency,parseFloat(app?.total_amount),true): processCurrency(app?.currency,parseFloat(app?.total_amount_in_other_currency),true) }}
                  </td>
                  <td>
                    {{app?.paid_amount ?
                      (processCurrency(app?.currency,parseFloat(app?.paid_amount),true))
                      : "--"}}
                  </td>
                  <td>
                    <i v-if="app?.gr_time" class="bx bx-check-circle text-success"></i>
                    <i v-else class="text-danger">X</i>
                  </td>
                  <td :title="moment(app?.created).format('h:mm a')">{{ moment(app?.created).format("YYYY-MM-DD") }}</td>
                  <td>
                    <div class="d-flex align-items-center">
                      <button :title="$t('popups.details')" @click="showDetailsModal(app.id)"
                               class="btn btn-info mx-1" href="javascript: void(0);"
                              role="button">
                        <i class="bx bx-show text-xl" style="font-size: 16px"></i>
                      </button>

                      <button :title="$t('popups.edit')" v-if="!app.po_time" @click="preEditOrder(app.id)"
                               class="btn btn-primary mx-1" href="javascript: void(0);" role="button">
                        <i class="bx bx-pencil text-xl" style="font-size: 16px"></i>
                      </button>
                      <button :title="$t('journal.complete')" v-if="!app.po_time" @click="preCompleteOrder(app.id)"
                               class="btn btn-primary mx-1" href="javascript: void(0);"
                              role="button">
                        <i class="bx bx-check-circle text-xl" style="font-size: 16px"></i>
                      </button>
                      <button :title="$t('purchases.transit.landed_cost')" v-if="app?.po_time && !app?.gr_time" @click="
                          preGoodInTransit(app.id);
                        " class="btn btn-primary mx-1"
                              href="javascript: void(0);" role="button">
                        <i class="fa fa-truck text-xl" style="font-size: 16px"></i>
                      </button>
                      <button :title="$t('purchases.debit_note')" v-if="app?.po_time" @click="
                          preDebitNote(app.id);
                        " class="btn btn-primary mx-1"
                              href="javascript: void(0);" role="button">
                        <i class="bx bx-log-out-circle text-xl" style="font-size: 16px"></i>
                      </button>
                      <button :title="$t('popups.delete')" v-if="!app.po_time" @click="deleteOrder(app.id)" class="btn btn-danger mx-1"
                              href="javascript: void(0);" role="button">
                        <i class="bx bx-trash text-xl" style="font-size: 16px"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!-- end  table -->
            <div v-if="tot_pages> 1">
              <!--   Apps  paginate     -->
              <ul class="paginate paginate-rounded justify-content-center mb-2">
                <li class="page-item" :class="{ disabled: page == 1 }">
                  <a class="page-link" href="javascript: void(0);" @click="searchModel ? search(page - 1) : get(page - 1)" aria-label="Previous">
                    <i class="mdi mdi-chevron-left"></i>
                  </a>
                </li>
                <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
                  <a class="page-link" href="javascript: void(0);" @click="searchModel ? search(page) : get(p)">{{
                      p
                    }}</a>
                </li>

                <li class="page-item" :class="{ disabled: page == total_pages }">
                  <a class="page-link" href="javascript: void(0);" @click="searchModel ? search(page + 1) : get(page + 1)" aria-label="Next">
                    <i class="mdi mdi-chevron-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- start complete modal -->
     <complete-order @on-complete="purchase_complete_modal.hide();get(page)" :vatValue="vatValue" :purchase_order="purchase_order" :cash_accounts="cash_accounts" />
    <!-- end complete modal -->

    <!--Start details Modal-->
    <purchase-order-details :vat-value="purchase_order?.vat_value" :order_details="purchase_order" />
    <!--End Add Modal-->

    <good-in-transit :purchase_order="purchase_order" @on-complete="getLandedCosts();" :order_data="purchase_order" :vat-value="purchase_order?.vat_value" :cash_accounts="cash_accounts" :landed-costs="landedCosts" :landed-cost-types="landedCostTypes"></good-in-transit>


    <debit-note @on-complete="debit_note_modal.hide();get(page)" :purchase_order="purchase_order" :vat-value="purchase_order?.vat_value" :cash_accounts="cash_accounts" :landed-costs="landedCosts"></debit-note>

    <!--Start Add Modal-->
    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
         :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
      <div class="modal-dialog modal-lg" style="max-width:1000px">
        <div class="modal-content">
          <form @submit.prevent="purchase_order.id ? editPurchaseOrder() : createPurchaseOrder()">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("popups.purchase_order") }}
              </h5>
              <button type="button" class="btn-close btn-close-add" data-bs-dismiss="modal" aria-label="Close"
                      style="margin: 0"></button>
            </div>

            <div class="modal-body">

              <!-- Supplier Information -->
              <div class="row">

                <!-- Type Import or Export -->
                <div class="mb-3 col-12 col-md-6">
                  <label for="" class="d-block">{{$t('popups.type')}}</label>
                  <select v-model="purchase_order.type" class="form-select mb-2">
                    <option value="export">{{$t("purchases.export_process")}}</option>
                    <option value="import">{{$t("purchases.import_process")}}</option>
                  </select>
                </div>

                <!-- Supplier -->
                <div class="mb-3 col-12 col-md-6" v-if="purchase_order.type">
                  <label for="" class="d-block">{{$t('purchases.select_supplier')}}</label>
                  <AutoComplete
                      v-model="purchase_order.supplier"
                      :suggestions="suppliers"

                      @item-select="selectSupplier"
                      @complete="supplierSearch($event.query)"
                      :complete-on-focus="true"
                      :optionLabel="showSupplierLabel"
                      style="color: #333 !important;width:100%" />
                </div>

                <!-- Currency -->
                <div class="mb-3 col-12 col-md-6" v-if="purchase_order?.supplier?.id">
                  <labele class="form-label">
                    {{ $t("popups.currency") }}
                  </labele>
                  <select v-model="purchase_order.currency" @change="currencyChanged" :disabled="purchase_order?.currenciesArray?.length == 1" class="form-select">
                    <option :value="currency" v-for="currency in purchase_order.currenciesArray" :key="currency">
                      {{
                        $i18n.locale == "ar"
                            ? currency?.name
                            : currency?.name_en
                      }}
                    </option>
                  </select>

                </div>

                <!-- Purchase Type -->
                <div class="mb-3 col-12 col-md-6" v-if="purchase_order?.supplier?.id">
                  <label for="asset_id">{{
                      $t("purchases.purchase_type")
                    }}</label>
                  <select required v-model="purchase_order.purchase_item_type" class="form-select mb-2"
                          :placeholder="$t('purchases.purchase_type')">
                    <option v-for="purchase_type in purchase_order?.purchase_agent_item_types" :key="purchase_type"
                            :value="purchase_type?.purchase_item_type">
                      {{
                        $i18n.locale == "ar"
                            ? purchase_type?.purchase_item_type?.name
                            : purchase_type?.purchase_item_type?.name_en
                      }}
                    </option>
                  </select>
                </div>

                <!-- Inventory -->
                <div class="mb-3 col-12 col-md-6" v-if="purchase_order?.supplier?.id && purchase_order?.purchase_item_type?.code == '111'">
                  <label for="asset_id">{{
                      $t("purchases.inventories")
                    }}</label>
                  <select required v-model="purchase_order.inventory_id" class="form-select mb-2">
                    <option v-for="item in inventories" :key="item" :value="item.id">
                      {{ $i18n.locale == 'ar' ? item?.name : item?.name_en }}
                    </option>
                  </select>
                </div>


              <!-- Driver -->
              <div class="mb-3 col-12 col-md-6" v-if="purchase_order?.supplier?.id && purchase_order?.purchase_item_type?.code == '111'">
                <label for="asset_id">{{
                    $t("purchases.driver")
                  }}</label>
                <input type="text" class="form-control" v-model="purchase_order.driver">
              </div>

            <!-- Plate Number -->
            <div class="mb-3 col-12 col-md-6" v-if="purchase_order?.supplier?.id && purchase_order?.purchase_item_type?.code == '111'">
              <label for="asset_id">{{
                  $t("purchases.plate_num")
                }}</label>
              <input type="text" class="form-control" v-model="purchase_order.plate_num">
            </div>


              </div>
        <div class="purchases_body row" v-if="purchase_order?.supplier?.id && purchase_order?.purchase_item_type?.id">
                <!-- Purchase Type is Products -->
                <div v-if="purchase_order.purchase_item_type?.code == '111'">
                  <div class="row">
                    <div class="row d-flex align-items-center">
                      <div class="col">
                        <label for="asset_id">{{
                            $t("purchases.chose_items")
                          }}</label>
                      </div>

                      <div class="col">
                        <button type="button" @click="purchase_order.items.push({})" style="float: inline-end;" class="btn">
                          <span class="bx bxs-plus-circle fa-2x" style="color: #2a3042 !important"></span>
                        </button>
                      </div>
                    </div>
                    <div v-for="(item,index) in purchase_order.items" :key="item"
                         class="d-flex justify-content-between flex-column border-bottom mt-2 pb-2">

                      <div class="d-flex justify-content-between align-items-center">

                        <!-- Product   -->
                        <div>
                          <label for="asset_id">{{
                              $t("purchases.product")
                            }}</label>
                          <AutoComplete
                              v-model="item.product"
                              :suggestions="matchedProducts"
                              @complete="searchProduct($event.query)"
                              :complete-on-focus="true"
                              :optionLabel="showProductsLabel"
                              style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                          />
                        </div>

                        <!-- Weight -->
                        <div>
                          <label>{{
                              $t("purchases.weight")
                            }}</label>
                          <input type="number" min="1" v-model="item.weight" @change="setGrossWeight()" class="form-control mb-2 mx-1"
                                 :placeholder="$t('purchases.weight')" />
                        </div>

                        <!-- Total Price -->
                        <div>
                          <label for="asset_id">{{
                              $t("purchases.total_price")
                            }}</label>
                          <currency-input v-model="item.total_price" class="form-control mb-2 mx-1"
                                 :placeholder="$t('purchases.total_price')" />
                        </div>

                        <!-- With VAT -->
                        <div class="from-check form-check-inline">
                          <label class="form-label">{{
                              $t("purchases.vat")
                            }}</label>
                          <p>
                            <input type="checkbox" v-model="item.vat" class="form-check-inline" />
                          </p>
                        </div>

                        <!-- VAT Value -->
                        <div>
                          <label class="form-label">{{
                              $t("purchases.vat_value")
                            }}</label>
                          <p>{{ item.vat ? vatValue*100+"%" : "-" }}</p>
                        </div>

                        <!-- Sub Total -->
                        <div>
                          <label for="" class="form-label">{{
                              $t("purchases.sub_total")
                            }}</label>
                          <p v-if="item.qty && item.total_price">
                            {{
                              (
                                  parseFloat(item?.total_price) *
                                  (item?.vat ? (1+parseFloat(vatValue)) : 1)
                              )?.toLocaleString()

                            }}
                          </p>
                          <p v-else>0</p>
                        </div>

                        <button type="button" @click="purchase_order.items.splice(index,1)" class="btn btn-danger">
                          <span class="bx bxs-trash" style="color: #fff !important"></span>
                        </button>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mt-2">
                        
                        <!-- Number -->
                        <div>
                          <label>{{
                              $t("purchases.number")
                            }}</label>
                          <input type="number" v-model="item.number" class="form-control mb-2 mx-1"
                                 :placeholder="$t('purchases.number')" />
                        </div>

                        <!-- Gross Weight -->
                        <div>
                          <label for="asset_id">{{
                              $t("purchases.gross_weight")
                            }}</label>
                          <input type="number" min="1" v-model="item.qty" class="form-control mb-2 mx-1"
                                 :placeholder="$t('purchases.gross_weight')" />
                        </div>

                        <!-- Transport Fees -->
                        <div>
                          <label>{{
                              $t("purchases.transport")
                            }}</label>
                          <input type="number" v-model="item.transport" class="form-control mb-2 mx-1"
                                 :placeholder="$t('purchases.transport')" />
                        </div>

                        <!-- Distnation -->
                        <div class="from-check form-check-inline">
                          <label class="form-label">{{
                              $t("sales.distnation")
                            }}</label>
                          <p>
                            <input type="text" v-model="item.distnation" class="form-control mb-2 mx-1"
                            :placeholder="$t('sales.distnation')" />
                          </p>
                        </div>

                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>

                  <!--       Total Order and Taxes             -->
                  <table class="table table-borderless d-inline-block mt-3 float-end" style="float: inline-end !important;font-weight: bold;">
                    <tr>
                      <td style="padding-right:30px">{{$t("purchases.total_untaxed")}}</td>
                      <td style="font-size: 90% !important;">
                        {{processCurrency(purchase_order.currency,untaxedTotal,true)}}
                      </td>
                    </tr>
                    <tr>
                      <td style="padding-right:30px">{{$t("purchases.total_tax")}}</td>
                      <td style="font-size: 90% !important;">

                        {{processCurrency(purchase_order.currency,taxTotal,true)}}


                      </td>
                    </tr>
                    <tr>
                      <td style="padding-right:30px">{{$t("purchases.total")}}</td>
                      <td style="font-size: 90% !important;">

                        {{processCurrency(purchase_order.currency,(taxTotal+untaxedTotal),true)}}

                      </td>
                    </tr>
                  </table>
                </div>

              </div>
              <div class="mt-3" v-if="purchase_order?.supplier?.id && purchase_order?.purchase_item_type?.id">
                <label class="form-label">{{$t("fixed_assets.comment")}}</label>
                <textarea class="form-control" v-model="purchase_order.comment"></textarea>
              </div>

            </div>
            <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
              <button type="submit" :disabled="!enableCreatePurchaseButton" class="btn btn-primary">
                {{ purchase_order.id ? $t("popups.edit")  : $t("popups.purchase_order") }}
              </button>
              <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Add Modal-->



    <!--end-->
  </Layout>
</template>

<style>
.flex-column {
  padding: 1rem;
  background: #f0efefdd;
}




[dir=rtl] input,[dir=rtl] input::placeholder{
  text-align:right;
}
</style>
