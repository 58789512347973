<script>
import CurrencyInput from "@/components/CurrencyInput";

export default {
  components: {CurrencyInput},
  props: ["purchase_order","cash_accounts","vatValue","landedCosts"],
  data() {
    return {
      tomorrow:null,
      matchedAccounts:[],
      matchedProducts:[],
      addLandedCost: [{}],
      suggested_gr_time:null,
      items: [{}],
      debit_note_data:{},
      addPayAccounts: [{}],
    };
  },
  watch: {
    purchase_order(new_value,old_value){
      if(old_value?.items != new_value?.items){
        this.items = [{}]
      }
    }
  },
  methods: {
    accountSearch(query){
      this.matchedAccounts = this.cash_accounts.filter(account=>
          (
              account.name.toLowerCase().includes(query.toLowerCase()) ||
              account.name_en.toLowerCase().includes(query.toLowerCase())
          ) &&
          !this.addPayAccounts.some(payAccount=>payAccount?.cash_account?.cash_account_id==account.cash_account_id)

      )
      console.log("this.matchedAccounts",this.matchedAccounts)
    },
    showLabel(account){
      console.log("ACCOUNT",account)
      return (this.$i18n.locale == "ar"
              ? account?.name
              : account?.name_en) + " - " +
          this.processCurrency(account,account.balance)
    },
    searchProduct(keyword){
      console.log("this.purchase_order.items",this.purchase_order.items)
      this.matchedProducts = this.purchase_order.items
          .filter(item=>(item.product.name.toLowerCase().includes(keyword.toLowerCase()) || item.product.name_en.toLowerCase().includes(keyword.toLowerCase())) && !this.items.find(i=>i.id==item.id) )
    },
    showProductsLabel(one) {
      return this.$i18n.locale == 'ar'? one.product.name : one.product.name_en;
    },
    selectProduct(item){
      for(let key in item.selected){
        item[key] = item.selected[key]
      }
      item.max_qty = item.qty
    },
    debitNote(){
      this.http.post("purchases/debit-note",{
        items:this.items,
        reason:this.debit_note_data?.reason,
        suggested_dp_time:this.debit_note_data?.suggested_dp_time,
        purchase_order_id:this.purchase_order.id,
        total_retured_landed_costs:this.totalLandedCostLoss,
        supplier_charge_landed_cost_loss:this.debit_note_data?.supplier_charge_landed_cost_loss,
        accounts:this.addPayAccounts,
      }).then(()=>{
        this.debit_note_data = {}
        this.$emit("onComplete")
      })
    }
  },
  computed:{
    totalLandedCostLoss(){
      return this.items.filter(item=>item.qty).reduce((sum,a)=>sum + parseFloat(a.shared_landed_cost) * parseFloat(a.qty),0)
    },
    totalLandedCost(){
      return this.landedCosts.reduce((sum,t)=>sum+parseFloat(t.value),0)
    },
    totalAddPayAccounts(){
      return this.addPayAccounts.filter(account=>account.value)
          .reduce((sum,account)=>{
            console.log("this.purchase_order?.currency?.id",this.purchase_order?.currency?.id,"sum",sum,"account",account)
            if(this.purchase_order?.currency?.id != account.cash_account.currency_id && this.purchase_order?.currency?.is_functional){
              sum += parseFloat(account.value) * parseFloat(account?.cash_account?.current_rate)
            }
            else if(this.purchase_order?.currency?.id !=account?.cash_account?.currency_id && account?.cash_account?.is_functional){
              sum += parseFloat(account.value) / parseFloat(this.purchase_order?.currency?.current_rate)
            }
            else if(this.purchase_order?.currency?.id != account?.cash_account?.currency_id){
              sum += parseFloat(account.value) * parseFloat(  this.purchase_order?.currency?.current_rate) / parseFloat(account?.cash_account?.current_rate)
            }
            else{
              sum += parseFloat(account.value)
            }
            return sum
          },0)
    },
    untaxedTotal(){
      return this.items.reduce((sum,item)=>{
        return sum + parseFloat(item.qty ? item.qty : 0) *
            parseFloat(item.unit_price ? item.unit_price : 0)
      },0)
    },
    taxTotal(){
      return this.items.reduce((sum,item)=>{
        return sum + parseFloat(item.qty ? item.qty : 0) *
            parseFloat(item.unit_price ? item.unit_price : 0) *
            (item?.vat ? (parseFloat(this.vatValue)) : 0)
      },0)
    },
  },
  mounted() {

  },
};
</script>
<template>
  <div class="modal fade" id="debitNoteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
       :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog modal-lg" style="max-width:1000px">
      <div class="modal-content">
        <form @submit.prevent="debitNote()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("purchases.debit_note") }}
            </h5>
            <button type="button" class="btn-close btn-close-add" data-bs-dismiss="modal" aria-label="Close"
                    style="margin: 0"></button>
          </div>

          <div class="modal-body">

            <div class="purchases_body row" v-if="purchase_order?.supplier?.id && purchase_order?.purchase_item_type?.id">
              <!-- Purchase Type is Products -->
              <div>
                <div class="row">
                  <div class="row d-flex align-items-center">
                    <div class="col">
                      <label for="asset_id">
                        {{
                          $t("purchases.debit_items")
                        }}
                      </label>
                    </div>

                    <div class="col">
                      <button v-if="items.length != purchase_order?.items?.length" type="button" @click="items.push({})" style="float: inline-end;" class="btn">
                        <span class="bx bxs-plus-circle fa-2x" style="color: #2a3042 !important"></span>
                      </button>
                    </div>
                  </div>
                  <div v-for="(item,index) in items" :key="item"
                       class="d-flex justify-content-between flex-column border-bottom mt-2 pb-2">

                    <div class="d-flex justify-content-between align-items-center">

                      <!-- Product   -->
                      <div>
                        <label for="asset_id">{{
                            $t("purchases.product")
                          }}</label>
                        <AutoComplete
                            v-model="item.selected"
                            @item-select="selectProduct(item)"
                            :suggestions="matchedProducts"
                            @complete="searchProduct($event.query)"
                            :complete-on-focus="true"
                            :optionLabel="showProductsLabel"
                            style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                        />
                      </div>

                      <!-- Quantity -->
                      <div>
                        <label for="asset_id">{{
                            $t("purchases.qty")
                          }} / {{item.max_qty}}</label>
                        <input type="number" :max="item.max_qty" v-model="item.qty" class="form-control mb-2 mx-1"
                               :placeholder="$t('purchases.qty')" />
                      </div>

                      <!-- Unit Price -->
                      <div>
                        <label for="asset_id">{{
                            $t("purchases.unit_price")
                          }}</label>
                        <currency-input disabled v-model="item.unit_price" class="form-control mb-2 mx-1"
                                        :placeholder="$t('purchases.unit_price')" />
                      </div>

                      <!-- With VAT -->
                      <div class="from-check form-check-inline">
                        <label class="form-label">{{
                            $t("purchases.vat")
                          }}</label>
                        <p>
                          <input disabled type="checkbox" v-model="item.vat" class="form-check-inline" />
                        </p>
                      </div>

                      <!-- VAT Value -->
                      <div>
                        <label class="form-label">{{
                            $t("purchases.vat_value")
                          }}</label>
                        <p>{{ item.vat ? vatValue*100+"%" : "-" }}</p>
                      </div>

                      <!-- Sub Total -->
                      <div>
                        <label for="" class="form-label">{{
                            $t("purchases.sub_total")
                          }}</label>
                        <p v-if="item.qty && item.unit_price">
                          {{
                            (
                                parseFloat(item?.qty) *
                                parseFloat(item?.unit_price) *
                                (item?.vat ? (1+parseFloat(vatValue)) : 1)
                            )?.toLocaleString()

                          }}
                        </p>
                        <p v-else>0</p>
                      </div>

                      <button type="button" @click="items.splice(index,1)" class="btn btn-danger">
                        <span class="bx bxs-trash" style="color: #fff !important"></span>
                      </button>
                    </div>
                  </div>
                </div>

                <h5 style="font-size:115%" class="mt-4">{{$t("inventories.total_landed_cost_loss")}}: <b>{{processCurrency(cash_accounts.find(a=>a.is_functional),totalLandedCostLoss,true)}}</b></h5>

                <div class="mb-1 mb-sm-3">
                  <label class="form-label">{{$t("inventories.supplier_charge_landed_cost_loss")}}</label>
                  <currency-input :disabled="totalLandedCostLoss==0" v-model="debit_note_data.supplier_charge_landed_cost_loss" :max="totalLandedCostLoss" class="form-control"></currency-input>
                </div>

                <!--       Total Order and Taxes             -->
                <table class="table table-borderless d-inline-block mt-3 float-end" style="float: inline-end !important;font-weight: bold;">
                  <tr>
                    <td style="padding-right:30px">{{$t("purchases.total_untaxed")}}</td>
                    <td style="font-size: 90% !important;">
                      {{processCurrency(purchase_order.currency,untaxedTotal,true)}}
                    </td>
                  </tr>
                  <tr>
                    <td style="padding-right:30px">{{$t("purchases.total_tax")}}</td>
                    <td style="font-size: 90% !important;">

                      {{processCurrency(purchase_order.currency,taxTotal,true)}}

                    </td>
                  </tr>
                  <tr v-if="totalLandedCost!=0">
                    <td style="padding-right:30px">{{$t("purchases.transit.landed_cost")}}</td>
                    <td style="font-size: 90% !important;">

                      {{processCurrency(purchase_order.currency,(purchase_order.currency?.is_functional ? debit_note_data?.supplier_charge_landed_cost_loss :debit_note_data?.supplier_charge_landed_cost_loss/purchase_order?.currency?.current_rate ),true)}}

                    </td>
                  </tr>
                  <tr>
                    <td style="padding-right:30px">{{$t("purchases.total")}}</td>
                    <td style="font-size: 90% !important;">

                      {{processCurrency(purchase_order.currency,(taxTotal+untaxedTotal+(purchase_order.currency?.is_functional ? debit_note_data?.supplier_charge_landed_cost_loss :debit_note_data?.supplier_charge_landed_cost_loss/purchase_order?.currency?.current_rate )),true)}}

                    </td>
                  </tr>
                </table>
              </div>

            </div>

          <!--  Cash Accounts -->
            <div class="">
              <div class="mt-3">
                <hr />
                <div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <label for="asset_id">{{
                          $t("purchases.chose_acccount")
                        }}</label>
                    </div>
                    <button type="button" @click="addPayAccounts.push({})" class="btn">
                      <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
                    </button>
                  </div>
                  <div v-for="(payAccount, aIndex) in addPayAccounts" :key="payAccount">
                    <div class="purchase-container d-flex">
                      <!--                          @item-select="selectSupplier"-->
                      <div class="row w-100">
                        <div class="col-12 col-md-5 mb-2 mb-md-1">
                          <AutoComplete
                              v-model="payAccount.cash_account"
                              :suggestions="matchedAccounts"

                              @complete="accountSearch($event.query)"
                              :complete-on-focus="true"
                              :optionLabel="showLabel"
                              style="color: #333 !important;width:100%" />
                        </div>
                        <div class="col-12 col-md-5 mb-2 mb-md-1">
                          <currency-input :disabled="!payAccount.cash_account" v-model="payAccount.value"
                                          class="form-control mb-2 mx-1" :placeholder="$t('purchases.amount')" ></currency-input>
                        </div>
                        <div class="col-12 col-md-2 mb-2 mb-md-1">
                          <button type="button" role="button" @click="addPayAccounts.splice(aIndex, 1)"
                                  :disabled="addPayAccounts.length == 1" class="btn btn-danger">
                            <i class="bx bx-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h6>{{$t("purchases.total")}}: {{processCurrency(purchase_order.currency,totalAddPayAccounts,true)}}</h6>
                  <h6>{{$t("purchases.remaining")}}: {{processCurrency(purchase_order.currency,(parseFloat(untaxedTotal) + parseFloat(taxTotal) + (purchase_order.currency?.is_functional ? debit_note_data?.supplier_charge_landed_cost_loss :debit_note_data?.supplier_charge_landed_cost_loss/purchase_order?.currency?.current_rate ) - totalAddPayAccounts),true)}}</h6>
                </div>

              </div>
              <hr>
              <div v-if="purchase_order.prepayment">
                {{$t("purchases.advance_to_supplier")}}: {{processCurrency(purchase_order.currency,purchase_order.prepayment,true)}}
              </div>
              <div v-if="purchase_order.previous_payable">
                {{$t("purchases.payable_to_supplier")}}: {{processCurrency(purchase_order.currency,purchase_order.previous_payable,true)}}
              </div>
            </div>

              <div class="mt-3">
                <label class="form-label">{{$t("purchases.reason")}}</label>
                <textarea class="form-control" v-model="debit_note_data.reason"></textarea>
              </div>
            <div class="mt-3">
              <label class="form-label">{{$t("purchases.suggested_dp_time")}}</label>
              <input type="date" class="form-control" v-model="debit_note_data.suggested_dp_time">
            </div>
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" :disabled="!(items.length>0 && items[0].qty) " class="btn btn-primary">
              {{  $t("popups.add") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
